import {
  promotion,
  community,
  dataFilters,
  article,
  video,
  cases,
  live_event,
  newsletter,
} from "../../js/path";
import moment from "moment";

export default {
  data() {
    return {
      pageNo: null,
      table_header: [],
      promotion_id: null,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "platform",
        },
        {
          key: "total_reach",
          label: "Target Reach",
          class: "promotion_heading",
        },
        {
          key: "target_reach_dashboard",
          label: "Target Reach for Dashboard",
          class: "promotion_heading",
        },
        {
          key: "ad_position",
          label: "Ad Position",
          class: "promotion_heading",
        },
        {
          key: "ad_placement",
          label: "Ad Placement",
          class: "promotion_heading",
        },
        {
          key: "ctr",
          label: "CTR (%)",
          class: "promotion_heading",
        },
        // {
        //    key: "ad_creative",
        //    label: "Ad Creative",
        //    class: "promotion_heading",
        // },
        {
          key: "ad_title",
          label: "Ad Title",
          class: "promotion_heading",
        },
        {
          key: "content_type",
          label: "Content Type",
          class: "promotion_heading",
        },
        {
          key: "content_title",
          label: "Content Title",
          class: "promotion_heading",
        },
        {
          key: "sponsor_name",
          label: "Sponser Name",
          class: "promotion_heading",
        },
        {
          key: "end_in",
          label: "End In",
          class: "promotion_heading",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "for_views",
          label: "For Views",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      params: "",
      filter: null,
      filterOn: [],
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      key: 0,
      activeTab: "all",
      currentPage: 1,
      platformType: [
        {
          text: "App",
          value: "app",
        },
      ],
      getPlacement: [
        {
          text: "Feed",
          value: "feed",
        },
        {
          text: "Journal",
          value: "journal",
        },
        {
          text: "Video Detail",
          value: "video_detail",
        },
        {
          text: "Case Detail",
          value: "case_detail",
        },
        {
          text: "Post Detail",
          value: "post_detail",
        },
        {
          text: "Newsletter Detail",
          value: "newsletter_detail",
        },
      ],
      content_type: [
        {
          text: "Video",
          value: "video",
        },
        {
          text: "Case",
          value: "case",
        },
        {
          text: "Newsletter",
          value: "newsletter",
        },
        {
          text: "Journal",
          value: "journal",
        },
        {
          text: "Poster",
          value: "poster",
        },
        {
          text: "Small Card",
          value: "small_card",
        },
        {
          text: "Live Event",
          value: "live_event",
        },
        {
          text: "Pop Up",
          value: "pop_up",
        },
      ],
      getCommunities: [],
      getDataFilter: [],
      form: {
        platform: "app",
        total_reach: "",
        total_views: "",
        content_type: "",
        content_id: "",
        data_filter_id: "",
        sponsor_name: "",
        ad_title: "",
        ad_position: "",
        ad_placement: [],
        cta_text: "",
        cta_link: "",
        cta_color: "#3167af",
        communities: "",
        is_active: 0,
        for_views: 0,
        start_datetime: "",
        end_datetime: "",
        ctr: 0,
        target_reach_dashboard: "",
      },
      getContentType: [],
      logo_img: "",
      logo_img_url: "",
      ad_creative_img: "",
      feed_creative: "",
      journal_creative: "",
      detail_creative: "",
      feed_creative_url: "",
      journal_creative_url: "",
      detail_creative_url: "",
      edit: {
        logo_img_url: null,
        feed_creative_url: null,
        journal_creative_url: null,
        detail_creative_url: null,
      },
      start_datetime: "",
      end_datetime: "",
      getFeed: [
        {
          value: "feed",
          text: "Feed",
        },
      ],
    };
  },
  methods: {
    getFormattedDate(end_date) {
      if (end_date) {
        let convertEnd = moment(end_date).format("YYYY-MM-DD");
        let currentDate = moment();
        let endDate = moment(convertEnd, "YYYY-MM-DD");

        if (endDate.isBefore(currentDate)) {
          return `ended`;
        }

        let noOfDays = endDate.diff(currentDate, "days");
        return `${noOfDays} days`;
      } else {
        return "---";
      }
    },
    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },
    disableStartDate(time) {
      return time.getTime() < this.start_datetime - 1;
    },
    getStartDateTime() {
      this.form.start_datetime = moment(this.start_datetime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    getEndDateTime() {
      this.form.end_datetime = moment(this.end_datetime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    async fetchCommunity(query) {
      try {
        const url = community.fetchAllActiveCommunity;
        const data = await this.getRequest(url + "&search=" + query);
        if (data.status) {
          this.getCommunities = data.response.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchDataFilter() {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = dataFilters.fetchAllDataFilter;
        const data = await this.getRequest(url);
        if (data.status) {
          this.getDataFilter = data.response.data;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchSingleAttachment(id) {
      if (this.form.content_type == "newsletter") {
        const url = newsletter.newsletterUrl;
        const data = await this.getRequest(url + "/" + id);
        if (data.status) {
          this.form.content_id = data.response;
        }
      } else if (this.form.content_type == "case") {
        const url = cases.caseUrl;
        const data = await this.getRequest(url + "/" + id);
        if (data.status) {
          this.form.content_id = data.response;
        }
      } else if (this.form.content_type == "video") {
        const url = video.videoUrl;
        const data = await this.getRequest(url + "/" + id);
        if (data.status) {
          this.form.content_id = data.response;
        }
      } else if (this.form.content_type == "journal") {
        const url = article.articleUrl;
        const data = await this.getRequest(url + "/" + id);
        if (data.status) {
          this.form.content_id = data.response;
        }
      } else if (this.form.content_type == "live_event") {
        const url = live_event.liveEventUrl;
        const data = await this.getRequest(url + "/" + id);
        if (data.status) {
          this.form.content_id = data.response;
        }
      }
    },
    async fetchAttachment(query) {
      if (this.form.content_type == "newsletter" && query != "") {
        const url = newsletter.newsletterUrl;
        const data = await this.getRequest(
          url + "?limit=50&filter=active&search=" + query
        );
        if (data.status) {
          this.getContentType = data.response.data;
        }
      } else if (this.form.content_type == "case" && query != "") {
        const url = cases.caseUrl;
        const data = await this.getRequest(
          url + "?limit=50&filter=active&search=" + query
        );
        if (data.status) {
          this.getContentType = data.response.data;
        }
      } else if (this.form.content_type == "video" && query != "") {
        const url = video.videoUrl;
        const data = await this.getRequest(
          url + "?limit=50&filter=active&search=" + query
        );
        if (data.status) {
          this.getContentType = data.response.data;
        }
      }
    },
    async fetchArticleBySearch(query) {
      if (this.form.content_type == "journal" && query != "") {
        const url = article.articleUrl;
        const data = await this.getRequest(
          url + "?limit=50&filter=active&search=" + query
        );
        if (data.status) {
          this.getContentType = data.response.data;
        }
      }
    },
    async fetchLiveEventBySearch(query) {
      if (this.form.content_type == "live_event" && query != "") {
        const url = live_event.liveEventUrl;
        const data = await this.getRequest(
          url + "?limit=50&filter=active&search=" + query
        );
        if (data.status) {
          this.getContentType = data.response.data;
        }
      }
    },
    async fetchSingleCommunity(id) {
      const url = community.fetchCommunity;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.form.communities.push(data.response);
      }
    },
    changeContentType() {
      this.getContentType = [];
      this.form.ad_placement = [];
      this.form.content_id = null;
    },
    readFile(e, txt) {
      if (txt == "logo_img") {
        this.logo_img = e.target.files[0];
        if (this.logo_img.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded file is More than 2MB",
          });
          this.$refs.logo_img.reset();
          this.logo_img_url = "";
          this.edit.logo_img_url = "";
          this.logo_img = "";
          return false;
        } else {
          this.logo_img_url = URL.createObjectURL(this.logo_img);
          this.edit.logo_img_url = "";
          return true;
        }
      } else if (txt == "feed_creative") {
        this.feed_creative = e.target.files[0];
        if (this.feed_creative.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded file is More than 2MB",
          });
          this.$refs.feed_creative.reset();
          this.feed_creative_url = "";
          this.edit.feed_creative_url = "";
          this.feed_creative = "";
          return false;
        } else {
          this.feed_creative_url = URL.createObjectURL(this.feed_creative);
          this.edit.feed_creative_url = "";
          return true;
        }
      } else if (txt == "journal_creative") {
        this.journal_creative = e.target.files[0];
        if (this.journal_creative.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded file is More than 2MB",
          });
          this.$refs.journal_creative.reset();
          this.journal_creative_url = "";
          this.edit.journal_creative_url = "";
          this.journal_creative = "";
          return false;
        } else {
          this.journal_creative_url = URL.createObjectURL(
            this.journal_creative
          );
          this.edit.journal_creative_url = "";
          return true;
        }
      } else if (txt == "detail_creative") {
        this.detail_creative = e.target.files[0];
        if (this.detail_creative.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded file is More than 2MB",
          });
          this.$refs.detail_creative.reset();
          this.detail_creative_url = "";
          this.edit.detail_creative_url = "";
          this.detail_creative = "";
          return false;
        } else {
          this.detail_creative_url = URL.createObjectURL(this.detail_creative);
          this.edit.detail_creative_url = "";
          return true;
        }
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) this.fetchData(this.activeTab);
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData(this.activeTab);
      } else {
        this.fetchData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(txt) {
      let url = null;
      if (txt == "active") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            promotion.promotionUrl +
            "?filter=" +
            txt +
            "&search=" +
            this.filter;
        } else {
          url = promotion.promotionUrl + "?filter=" + txt;
        }
      } else if (txt == "trash") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url =
            promotion.promotionUrl +
            "?filter=" +
            txt +
            "&search=" +
            this.filter;
        } else {
          url = promotion.promotionUrl + "?filter=" + txt;
        }
      } else if (txt == "all") {
        console.log(txt, "txt");
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          console.log("inside if**");
          url =
            promotion.promotionUrl +
            "?filter=" +
            txt +
            "&search=" +
            this.filter;
        } else {
          console.log("inside else**");
          url = promotion.promotionUrl + "?filter=" + txt;
        }
      }
      url += this.params;
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    async updateStatus(id) {
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = promotion.updateStatus + "/" + id;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
        });
        if (data.status) {
          const responseData = data.response;
          this.tableData.data[index] = responseData;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
    },
    async fetchPromotion(id) {
      this.$store.commit("toast/updateStatus", true);
      try {
        let url = promotion.promotionUrl + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData.platform != "" && responseData.platform != null) {
            this.form.platform = responseData.platform;
          }
          if (
            responseData.total_reach != "" &&
            responseData.total_reach != null
          ) {
            this.form.total_reach = responseData.total_reach;
          }
          if (
            responseData.target_reach_dashboard != "" &&
            responseData.target_reach_dashboard != null
          ) {
            this.form.target_reach_dashboard =
              responseData.target_reach_dashboard;
          }
          if (responseData.ad_title != "" && responseData.ad_title != null) {
            this.form.ad_title = responseData.ad_title;
          }
          if (
            responseData.content_type != "" &&
            responseData.content_type != null
          ) {
            this.form.content_type = responseData.content_type;
          }
          if (responseData.cta_link != "" && responseData.cta_link != null) {
            this.form.cta_link = responseData.cta_link;
          }
          if (responseData.cta_text != "" && responseData.cta_text != null) {
            this.form.cta_text = responseData.cta_text;
          }
          if (responseData.cta_color != "" && responseData.cta_color != null) {
            this.form.cta_color = responseData.cta_color;
          }
          if (
            responseData.sponsor_name != "" &&
            responseData.sponsor_name != null
          ) {
            this.form.sponsor_name = responseData.sponsor_name;
          }
          if (
            responseData.total_reach != "" &&
            responseData.total_reach != null
          ) {
            this.form.total_reach = responseData.total_reach;
          }
          if (
            responseData.total_views != "" &&
            responseData.total_views != null
          ) {
            this.form.total_views = responseData.total_views;
          }
          if (
            responseData.ad_placement != "" &&
            responseData.ad_placement != null
          ) {
            this.form.ad_placement = this.getPlacement.filter((item) => {
              if (responseData?.ad_placement?.includes(item?.value)) {
                return item;
              }
            });
          }
          if (
            responseData.ad_position != "" &&
            responseData.ad_position != null
          ) {
            this.form.ad_position = responseData.ad_position;
          }
          if (responseData.ctr != "" && responseData.ctr != null) {
            this.form.ctr = responseData.ctr;
          }
          if (
            responseData.communities != "" &&
            responseData.communities != null
          ) {
            this.form.communities = [];
            responseData.communities.map((item) => {
              if (item.community_id != null && item.community_id != "") {
                this.fetchSingleCommunity(item.community_id);
              }
            });
          }
          if (responseData.is_active) {
            this.form.is_active = true;
          }
          if (responseData.for_views) {
            this.form.for_views = true;
          }
          if (
            responseData.content_id != "" &&
            responseData.content_id != null
          ) {
            this.form.content_id = this.fetchSingleAttachment(
              responseData.content_id
            );
          }
          if (
            responseData.data_filter_id != "" &&
            responseData.data_filter_id != null
          ) {
            this.form.data_filter_id = responseData.data_filter_id;
          }
          if (responseData.logo != "" && responseData.logo != null) {
            this.edit.logo_img_url = responseData.logo ? responseData.logo : "";
          }
          if (
            responseData.start_datetime != "" &&
            responseData.start_datetime != null
          ) {
            this.start_datetime = moment(
              responseData.start_datetime,
              moment.defaultFormat
            ).toDate();
          }
          if (
            responseData.end_datetime != "" &&
            responseData.end_datetime != null
          ) {
            this.end_datetime = moment(
              responseData.end_datetime,
              moment.defaultFormat
            ).toDate();
          }
          if (responseData.ad_creative.feed) {
            this.edit.feed_creative_url = responseData.ad_creative.feed;
          }
          if (responseData.ad_creative.journal) {
            this.edit.journal_creative_url = responseData.ad_creative.journal;
          }
          if (responseData.ad_creative.detail) {
            this.edit.detail_creative_url = responseData.ad_creative.detail;
          }
          this.promotion_id = responseData.id;
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Fetching error!",
        });
      }
      this.$store.commit("toast/updateStatus", false);
    },
    async deletePromotion(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = promotion.promotionUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async restorePromotion(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = promotion.restorePromotion + "/" + id;
        const data = await this.postRequest(url, {});
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      try {
        this.submitted = true;
        if (
          this.$route.name == "add-promotion" ||
          this.$route.name == "edit-promotion"
        ) {
          if (this.form.ad_placement.length > 0) {
            console.log(
              "in*****",
              this.form.ad_placement.filter((f) => f.value == "feed")
            );
            if (
              this.feed_creative == "" &&
              this.edit.feed_creative_url == null &&
              this?.form?.ad_placement?.some((item) => item?.value === "feed")
            ) {
              console.log("in feed");
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: "Feed Creative is required",
              });
              return false;
            }
            if (
              this.journal_creative == "" &&
              this.edit.journal_creative_url == null &&
              this?.form.ad_placement.some((item) => item.value === "journal")
            ) {
              console.log("in journal");
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: "Journal Creative is required",
              });
              return false;
            }
            if (
              this.detail_creative == "" &&
              this.edit.detail_creative_url == null &&
              this.form.ad_placement.some((item) =>
                item.value.includes("_detail")
              )
            ) {
              console.log("in detail");
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: "Detail Creative is required",
              });
              return false;
            }
          }
          if (this.form.ctr > 100) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "CTR should be less than 100%",
            });
            return false;
          }
          if (this.form.ctr < 0) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "CTR cannot be negative",
            });
            return false;
          }
        }
        this.$v.$touch();
        if (this.$v.$invalid) {
          // window.scrollTo({
          //    top: 0,
          //    behavior: "smooth",
          // });
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        if (this.form.total_views <= 0) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Total views should be greater than 0",
          });
          return false;
        }
        if (this.form.total_reach <= 0) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Total Reach should be greater than 0",
          });
          return false;
        }
        if (this.form.target_reach_dashboard <= 0) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Target Reach for Dashboard should be greater than 0",
          });
          return false;
        }
        if (this.start_datetime == "" || this.start_datetime == null) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Start DateTime is required",
          });
          return false;
        }
        if (this.end_datetime == "" || this.end_datetime == null) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "End DateTime is required",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        let url = promotion.promotionUrl;
        if (this.$route.name == "edit-promotion") {
          url = promotion.promotionUrl + "/" + this.promotion_id;
        }
        this.getStartDateTime();
        this.getEndDateTime();
        let dataAppend = new FormData();

        if (this.form.is_active) {
          dataAppend.append("is_active", 1);
        } else {
          dataAppend.append("is_active", 0);
        }

        if (this.form.for_views) {
          dataAppend.append("for_views", 1);
        } else {
          dataAppend.append("for_views", 0);
        }

        if (this.logo_img) {
          dataAppend.append("logo", this.logo_img);
        }

        if (
          this.form.ad_placement.some((item) => item.value == "feed") &&
          this.feed_creative
        ) {
          dataAppend.append("ad_creative[feed]", this.feed_creative);
        }

        if (
          this.form.ad_placement.some((item) => item.value == "journal") &&
          this.journal_creative
        ) {
          dataAppend.append("ad_creative[journal]", this.journal_creative);
        }

        if (
          this.form.ad_placement.some((item) =>
            item.value.includes("_detail")
          ) &&
          this.detail_creative
        ) {
          dataAppend.append("ad_creative[detail]", this.detail_creative);
        }

        if (this.form.content_type === "poster" || this.form.content_type === "small_card") {
          dataAppend.append("content_id", 0);
        } else {
          if (this.form.content_id != null && this.form.content_id != "") {
            dataAppend.append("content_id", this.form.content_id.id);
          } else {
            dataAppend.append("content_id", "");
          }
        }

        for (var key in this.form) {
          if (
            key != "is_active" &&
            key != "for_views" &&
            key != "communities" &&
            key != "logo" &&
            key != "feed_creative" &&
            key != "journal_creative" &&
            key != "detail_creative" &&
            key != "content_id" &&
            key != "ad_placement"
          ) {
            dataAppend.append(key, this.form[key]);
          }
        }

        if (this.form.communities != null && this.form.communities != "") {
          const community = this.form.communities.map((c) => c.id);
          community.forEach((item) => dataAppend.append("communities[]", item));
        }

        if (this.form.ad_placement != null && this.form.ad_placement != "") {
          const ad_placement = this.form.ad_placement.map((c) => c.value);
          ad_placement.forEach((item) =>
            dataAppend.append("ad_placement[]", item)
          );
        }

        if (this.$route.name == "edit-promotion") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/promotion");
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    tabActive() {
      if (this.activeTab == "trash") {
        if (this.table_header.includes("delete")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          this.fields[index] = {
            key: "restore",
          };
          let table_index = this.table_header.findIndex(
            (item) => item == "delete"
          );
          this.table_header[table_index] = "restore";
        } else {
          this.table_header.push("restore");
          this.fields.push({
            key: "restore",
          });
        }

        if (!this.can("restore-promotion")) {
          let index = this.fields.findIndex((item) => item.key == "restore");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "restore"
            );
            this.table_header.splice(table_index, 1);
          }
        }

        let index = this.fields.findIndex((item) => item.key == "edit");
        if (index !== -1) {
          this.fields.splice(index, 1);
          let table_index = this.table_header.findIndex(
            (item) => item == "edit"
          );
          this.table_header.splice(table_index, 1);
        }
      } else {
        if (this.table_header.includes("restore")) {
          if (!this.can("edit-promotion")) {
            this.fields[14] = {
              key: "delete",
            };
          } else {
            this.fields[15] = {
              key: "delete",
            };
          }

          let table_index = this.table_header.findIndex(
            (item) => item == "restore"
          );
          this.table_header[table_index] = "delete";
        }

        if (!this.can("edit-promotion")) {
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("edit")) {
            this.table_header.push("edit");
            this.fields[14] = {
              key: "edit",
            };
          }
        }
        if (!this.can("delete-promotion")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "delete"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("delete")) {
            this.table_header.push("delete");
            this.fields[15] = {
              key: "delete",
            };
          }
        }
      }
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
    "form.content_type"(v){
      if (v == 'pop_up') {
        this.form.ad_position = 1
      }else{
        this.form.ad_position = ""
      }
    }
  }, 
  created() {
    if (
      this.$route.name == "add-promotion" ||
      this.$route.name == "edit-promotion"
    ) {
      if (this.$route.name == "edit-promotion") {
        this.fetchPromotion(this.$route.params.id);
      }
      this.fetchDataFilter();
    } else {
      this.fetchData(this.activeTab);
    }
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    this.tabActive();
  },
};
