<template>
   <Layout>
      <PageHeader :title="title" :items="items"></PageHeader>
      <div class="row" id="promotion">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <div class="row align-items-center mb-4">
                     <div class="col-md-6">
                        <div class="form-inline navbar-search">
                           <div class="input-group">
                              <input name="title" class="form-control bg-light border-0 small" placeholder="Search..."
                                 aria-label="Search" aria-describedby="basic-addon2" value="" v-model="filter"
                                 v-on:keyup="search" />
                              <div class="input-group-append">
                                 <button class="btn btn-search" @click="searchFor">
                                    <i class="fas fa-search fa-sm"> </i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="all-tabs">
                           <div :class="activeTab == 'all' ? ' tab-options' : ''" @click.prevent="fetchData('all')"
                              class="mx-2 opt">
                              All
                           </div>
                           |
                           <div :class="activeTab == 'trash' ? ' tab-options' : ''" @click.prevent="fetchData('trash')"
                              class="mx-2 opt">
                              Trash
                           </div>
                           |
                           <div :class="activeTab == 'active' ? ' tab-options' : ''"
                              @click.prevent="fetchData('active')" class="mx-2 opt">
                              Active
                           </div>
                           <template v-if="can('add-promotion')">
                              |
                              <div class="d-flex align-items-center">
                                 <svg class="svg-inline--fa fa-table mx-2" height="16px" width="16px" aria-hidden="true"
                                    focusable="false" data-prefix="fa" data-icon="table" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                    <path fill="currentColor"
                                       d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z">
                                    </path>
                                 </svg>
                                 <router-link :to="{ name: 'add-promotion' }" class="opt">Add Promotion</router-link>
                              </div>
                           </template>
                        </div>
                     </div>
                  </div>
                  <div class="table-responsive mb-0">
                     <b-table striped bordered :items="tableData.data" :fields="fields" :sort-by.sync="sortBy"
                        :per-page="0" :current-page="currentPage" :sort-desc.sync="sortDesc"
                        :filter-included-fields="filterOn" @filtered="onFiltered" responsive="sm"
                        class="table-bordered table-hover" :key="key">
                        <template v-can="'edit-promotion'" v-slot:cell(edit)="row">
                           <router-link :to="{
                              name: 'edit-promotion',
                              params: { id: row.item.id },
                           }">
                              <i class="fa fa-edit edit-i"></i>
                           </router-link>
                        </template>
                        <template v-can="'update-promotion-status'" v-slot:cell(is_active)="row">
                           <b-form-checkbox switch class="mb-1" @change="updateStatus(row.item.id)"
                              :checked="row.item.is_active == 1 ? true : false">
                           </b-form-checkbox>
                        </template>
                        <template v-slot:cell(platform)="row">
                          <span v-if="row.item.platform" style="text-transform: capitalize; font-size: 12px" class="badge" :class="row.item.platform == 'app' ? 'badge-info' : 'badge-primary'">{{ row.item.platform }}</span>
                        </template>
                        <template v-slot:cell(ad_placement)="row">
                        <template v-for="item in row.item.ad_placement">
                          <span v-if="item" :key="item" style="font-size: 12px; text-transform: capitalize" class="badge border border-secondary m-1"> {{ item?.includes('_') ? item?.replaceAll('_', ' ') : item }}</span>
                        </template>
                        </template>
                        <template v-if="fields[fields.length - 1].key == 'delete'" v-slot:cell(delete)="row"
                           v-can="'delete-promotion'">
                           <div v-if="activeTab != 'trash'" @click.prevent="deletePromotion(row.item.id)">
                              <i class="mdi mdi-trash-can delete-i"></i>
                           </div>
                        </template>
                        <template v-else v-slot:cell(restore)="row" v-can="'restore-promotion'">
                           <div v-if="activeTab == 'trash'" @click.prevent="restorePromotion(row.item.id)">
                              <i class="mdi mdi-restore restore-i"></i>
                           </div>
                        </template>
                        <template v-slot:cell(ad_title)="row">
                           <span v-if="row.item.ad_title" class="promotion_heading" style="width: 220px">{{ row.item.ad_title }}</span>
                           <span v-else>---</span>
                        </template>
                        <template v-slot:cell(content_type)="row">
                          <span v-if="row.item.content_type" style="text-transform: capitalize; font-size: 12px" class="badge"
                                 :class="row.item.content_type == 'video' ? 'badge-secondary' : row.item.content_type == 'case' ? 'badge-warning' : row.item.content_type == 'journal' ? 'badge-info' : row.item.content_type == 'newsletter' ? 'badge-danger' : row.item.content_type == 'live_event' ? 'badge-success' : row.item.content_type == 'poster' ? 'badge-primary' : row.item.content_type == 'small_card' ? 'badge-info' : row.item.content_type == 'pop_up' ? 'badge-secondary' : ''">{{
                                    row.item.content_type.replace(/_/, " ") }}</span>
                        </template>
                        <template v-slot:cell(content_title)="row">
                           <span v-if="row.item.content_title" class="promotion_heading">{{ row.item.content_title }}</span>
                           <span v-else>---</span>
                        </template>
                        <template v-slot:cell(sponser_name)="row">
                           <span v-if="row.item.sponser_name" class="promotion_heading">{{ row.item.sponser_name }}</span>
                           <span v-else>---</span>
                        </template>
                        <template v-slot:cell(ctr)="row">
                           <span v-if="row.item.ctr" class="promotion_heading">{{ row.item.ctr }}</span>
                           <span v-else>---</span>
                        </template>
                        <template v-slot:cell(ad_position)="row">
                           <span v-if="row.item.ad_position" class="promotion_heading">{{ row.item.ad_position }}</span>
                           <span v-else>---</span>
                        </template>
                        <template v-slot:cell(target_reach_dashboard)="row">
                           <span v-if="row.item.target_reach_dashboard" class="promotion_heading">{{ row.item.target_reach_dashboard }}</span>
                           <span v-else>---</span>
                        </template>
                        <template v-slot:cell(end_in)="row">
                           <span class="badge badge-danger" style="font-size: 12px">{{ getFormattedDate(row.item.end_datetime) }}</span>
                        </template>
                     </b-table>
                  </div>
                  <template v-if="tableData.total_pages > 0">
                     <div class="data_paginate">
                        <div class="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                           <ul class="pagination pagination-rounded mb-0">
                              <b-pagination v-model="currentPage" first-number last-number :total-rows="tableData.total"
                                 :total-pages="tableData.total_pages" :per-page="tableData.per_page"></b-pagination>
                           </ul>
                        </div>
                        <div class="form-inline navbar-search">
                           <span class="mr-2">Go to a Particular Paginated Page :</span>
                           <div class="input-group">
                              <b-form-input id="case" v-model="pageNo" placeholder="Enter Page No" class="in-width">
                              </b-form-input>
                              <div class="input-group-append">
                                 <button class="btn btn-search" @click="filterPage">
                                    <i class="fas fa-search fa-sm"> </i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </template>
               </div>
            </div>
         </div>
      </div>
   </Layout>
</template>
 
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import promotionMixin from "../../../mixins/ModuleJs/promotion";

export default {
   data() {
      return {
         title: "Promotion",
         items: [
            {
               text: "Home",
               href: "/",
            },
            {
               text: "List",
            },
         ],
      };
   },
   mixins: [MixinRequest, promotionMixin],
   components: {
      Layout,
      PageHeader,
   },
};
</script>
 